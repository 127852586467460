<template>
  <div style="position: relative;">
    <div class="mb-2">
      <label
        class="select-weather-label">
        Weather data source
      </label>
      <b-dropdown
        variant="outline-primary"
        no-caret
        class="select-weather-dropdown d-flex"
        menu-class="drop-down-weather-menu">
        <template #button-content>
          <div class="d-flex flex-row justify-content-between">
            <span>{{metSourceOptions[customMetUploadRadioValue]}}</span> <b-icon-caret-down-fill class="pull-right mt-2" font-scale="0.65"/>
          </div>  
        </template>
        <b-dropdown-item
          v-for="(option, index) in metSourceOptions"
          :key="option"
          @click="setCustomMetUploadRadioValue(index)">
          {{ option }}
        </b-dropdown-item>
      </b-dropdown>
    </div>                                     
    <div v-html="explanationText()"
      v-show='customMetUploadRadioValue!=2' 
      class="ml-1 mb-3 text-primary" 
      style="font-size:small;"></div>                            
    <div class="my-2">
      <label 
        class="select-weather-label"
        v-if="customMetUploadRadioValue==0">
        Weather Station
      </label>
      <!-- this needs to be a <b-dropdown> so that the 'Load more...'  doesn't close the dropdown-->
      <!-- when implemented with formulate (or any other <select>) it is impossible to prevent it from closing -->
      <b-dropdown 
        no-caret
        variant="outline-primary"
        no-flip
        class="select-weather-dropdown d-flex"
        menu-class="drop-down-weather-menu"
        v-if="customMetUploadRadioValue==0">
        <template #button-content>
          <div class="d-flex flex-row justify-content-between">
            <span>{{selectedStation || 'Please select a weather station'}}</span> <b-icon-caret-down-fill class="pull-right mt-2" font-scale="0.65"/>
          </div>  
        </template>
        <b-dropdown-item
          v-for="station in stationsToDisplay"
          :key="station"
          @click="getSelectedStation(station)">
          {{ station }}
        </b-dropdown-item>
        <b-dropdown-item 
          v-if="showCondensedStationList"
          @click.native.capture.stop="loadMoreStations">
          Load more...
        </b-dropdown-item>
      </b-dropdown> 
    </div>
    <div class="no-top-margin-all-descendants" :class="mapSizeClass" v-if="latitude" @update:center="centerUpdate" @update:zoom="zoomUpdate">
      <div v-if="latitude && customMetUploadRadioValue!=2" style="height: inherit; position: relative;">
        <l-map :center="get_map_center" :zoom="zoom" ref="map" :options="mapOptions" @update:zoom="zoomUpdate" @update:center="centerUpdate" style="height: inherit; width: inherit; position: relative;">
          <l-tile-layer :url="url" :attribution="attribution" ></l-tile-layer>
          <div v-if="customMetUploadRadioValue==0">
            <l-marker v-for="item in reducedMarkerItems" :key="item.id" :lat-lng="item.latlng" :icon="setStationIcon(item.latlng)">                    
            <l-tooltip>
              {{ item.name }} ({{ item.latlng[0].toFixed(2) }}, {{ item.latlng[1].toFixed(2) }})
            </l-tooltip> 
            </l-marker>
          </div> 
          <l-control position="bottomright" class="map-legend-control">
            <div id='map-legend'>
              <div class="legend-container">
                <div class="legend-item d-flex px-1">
                  <img class="legend-marker mr-2" src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png" alt="Red Marker"> 
                  <p class="legend-item-description text-white"> Project Location</p>
                </div>
                <div class="legend-item d-flex px-1">
                  <img class="legend-marker mr-2" src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png" alt="Blue Marker"> 
                  <p class="legend-item-description text-white"> Available Stations</p>
                </div>
                <div class="legend-item d-flex px-1">
                  <img class="legend-marker mr-2" src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png" alt="Green Marker"> 
                  <p class="legend-item-description text-white"> Selected Station</p>
                </div>
              </div>
            </div>
          </l-control>
          <l-marker :lat-lng="getProjectLatLng" :icon="setProjectIcon()">                    
            <l-tooltip>
              Project's Location ({{ latitude }}, {{ longitude }})
            </l-tooltip>                                      
          </l-marker>               
        </l-map>
      </div>
      <div v-show="customMetUploadSelected">
        <label class="select-weather-label">
          Please specify meteorological weather data record type
        </label>
        <b-dropdown 
          variant="outline-primary"
          no-caret
          class="select-weather-dropdown d-flex"
          menu-class="drop-down-weather-menu">
          <template #button-content>
            <div class="d-flex flex-row justify-content-between">
              <span>{{metFileFormatRadioValue}}</span> <b-icon-caret-down-fill class="pull-right mt-2" font-scale="0.65"/>
            </div>  
          </template>
          <b-dropdown-item
            @click="selectMetFileFormat('MIS')">
            MIS
          </b-dropdown-item>
          <b-dropdown-item
            @click="selectMetFileFormat('TC360')">
            TC360
          </b-dropdown-item>
          <b-dropdown-item
            @click="selectMetFileFormat('A3N')">
            A3N
          </b-dropdown-item>
          <b-dropdown-item
            @click="selectMetFileFormat('WEIBULL')">
            Weibull
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div v-text="fileExtensionDescription()"
        v-show='customMetUploadSelected' 
        class="ml-1 mb-3 text-primary" 
        style="font-size:small;white-space:pre-wrap;">
      </div>
      <div v-show="customMetUploadSelected">
        <FormulateInput 
          accept=".csv, .tc360, .a3n"
          type='file'
          :label="`${getMetFileFormat} Weather Data Upload`"
          v-model='customWeatherDataFile'
          validation="validateFileType"
          :validation-rules="{ validateFileType }"
          :validation-messages="{
            validateFileType: 'File must be of type csv, tc360, or a3n'}"
          class="my-3"
          :help="`Please select one csv file to upload. The file should be in ${getMetFileFormat} format`"
          @file-upload-complete="fileUploaded"/>
        <FormulateInput 
          v-show='customMetUploadSelected'
          type='text'
          label='label'
          v-model='customWeatherDataLabel'
          validation="optional"
          error-behavior="live"
          />
        <FormulateInput 
          v-if="metFileFormatRadioValue != 'A3N'"
          v-show='customMetUploadSelected'
          type='number'
          label='Meteorological Data Height (m)'
          v-model='customWeatherDataHeight'/>
      </div>
      <div v-show="customMetUploadRadioValue==3" >
        <FormulateInput v-model="timeframe" type="radio" :options="['Current', '2050', '2100']" label="Time frame" outer-class="mb-0" wrapper-class="d-flex" label-class="mr-5" element-class="d-flex mt-1" input-class="mr-3 mb-2" />
        <div class="d-flex flex-row">
          <FormulateInput v-model="climateScenario" type="radio" :options="['SSP1', 'SSP2', 'SSP3', 'SSP4', 'SSP5', ]" label="Climate scenario" outer-class="mb-2" wrapper-class="d-flex " label-class="mr-3 mb-0" element-class="d-flex mt-1" input-class="mr-3 mb-0"  />
          <a class="ml-4 mt-1" target='blank' href="https://en.wikipedia.org/wiki/Shared_Socioeconomic_Pathways#:~:text=Shared%20Socioeconomic%20Pathways%20(SSPs)%20are,scenarios%20with%20different%20climate%20policies">Learn More</a>
        </div>
        <b-card>
          <h5>{{climateScenarioTitle}}</h5>
          <p class="mt-2">{{climateScenarioDescription}}</p>
        </b-card>
      </div>
    </div>                
  </div>
</template>

<script>
import axios  from 'axios';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { LMap, LTileLayer, LMarker, LTooltip, LControl} from 'vue2-leaflet';
import { latLng, icon } from 'leaflet';
import { 
  SSP1_title,
  SSP2_title,
  SSP3_title,
  SSP4_title,
  SSP5_title,
  SSP1_description,
  SSP2_description,
  SSP3_description,
  SSP4_description,
  SSP5_description    
} from '@/constants/climateScenarios';

export default {
  name: 'WeatherDataForm',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LControl
  },
  props: {
    latitude: {
      required: true
    },
    longitude: {
      required: true
    },
    displayStationsFlag: {
      required: true, 
      type: Boolean
    },
    existingStationsForProject: {
      require: false,
      type: Array,
      default: () => []
    },
    includeModelledDataOption: {
      require: false,
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      customWeatherDataLabel: null,
      customMetUploadRadioValue: 0,
      selectedStation: null,
      numOfYears: 0,
      //creating a watcher on the amount of markers to dynamically update minNumOfYears would be nice
      minNumOfYears: 3, // shows 4 markers(the index 0 is counted )
      selectedStationData: {},
      showCondensedStationList: true,
      customWeatherDataFile: null,
      customWeatherDataHeight: 600,
      metFileFormatRadioValue: 'MIS',
      markeritems: [], 
      stations: [],
      allStations: [],
      allMarkers: [],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      apiBaseUrl: 'https://api.meteomatics.com/find_station?location=',
      userName: 'orbital_stack',
      passWord: '2Sp9p35JccAP',
      zoom: 7,
      airKeys: ['aero', 'drom', 'air', 'field', 'base', 'flug'],
      indexArray: [],
      mapOptions: {
        doubleClickZoom: 'center',
        zoomSnap: 0.75,
        attributionControl: false,
      },
      greenIcon: icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
        shadowUrl: '/src/assets/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
      }),
      redIcon: icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
        shadowUrl: '/src/assets/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
      }),
      blueIcon: icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
        shadowUrl: '/src/assets/img/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
      }),
      timeframe: '2050',
      climateScenario: 'SSP1'
    };
  },
  computed: {
    reducedMarkerItems(){
      return this.showCondensedStationList ? this.markeritems : this.allMarkers;
    },
    climateScenarioTitle() {
      if (this.climateScenario == 'SSP1') {
        return SSP1_title;
      } else if (this.climateScenario == 'SSP2') {
        return SSP2_title;
      } else if (this.climateScenario == 'SSP3') {
        return SSP3_title;
      } else if (this.climateScenario == 'SSP4') {
        return SSP4_title;
      } else if (this.climateScenario == 'SSP5') {
        return SSP5_title;
      }
      return '';
    },
    climateScenarioDescription() {
      if (this.climateScenario == 'SSP1') {
        return SSP1_description;
      } else if (this.climateScenario == 'SSP2') {
        return SSP2_description;
      } else if (this.climateScenario == 'SSP3') {
        return SSP3_description;
      } else if (this.climateScenario == 'SSP4') {
        return SSP4_description;
      } else if (this.climateScenario == 'SSP5') {
        return SSP5_description;
      }
      return [];
    },
    metSourceOptions() {
      let metSourceOptions = {};
      metSourceOptions[0] = 'Measured data from a nearby weather station'; 
      if (this.includeModelledDataOption) {
        metSourceOptions[1] = 'Modelled data at your project site';
      }
      
      if(this.hasModuleByName('custom met')) {
        metSourceOptions[2] = 'Upload custom weather data';
      }
      if (this.hasModuleByName('future met')) {
        metSourceOptions[3] = 'Future climate scenario';
      }
      return metSourceOptions;
    },
    stationsToDisplay() {
      if(this.showCondensedStationList) {
        let airportStations = this.stations?.filter(station => this.airKeys.some(airKey => station.toLowerCase().includes(airKey)));
        if(!airportStations) {
          return [];
        } else if(airportStations.length) {
          return airportStations.slice(0, this.numOfYears);
        } else {
          return this.stations.slice(0, this.numOfYears);
        }
      } else {
        return this.allStations;
      }
    },
    mapSizeClass() {
      if (this.customMetUploadRadioValue == 1) {
        return 'map-large';
      } else if (this.customMetUploadRadioValue == 0){
        return 'map-small';
      } else {
        return '';
      }
    },
    get_map_center(){
      if (this.latitude && this.longitude) {
        return latLng(this.latitude, this.longitude);
      } else {
        return latLng(0, 0);      
      }
    },
    getProjectLatLng() {
      return latLng(this.latitude, this.longitude);
    },
    customMetUploadSelected(){
      return this.customMetUploadRadioValue == 2 ? true : false;
    },
    getMetFileFormat(){
      if(this.metFileFormatRadioValue === 'TC360'){
        return 'TC360';
      } else if (this.metFileFormatRadioValue === 'A3N') {
        return 'A3N';
      } else if (this.metFileFormatRadioValue == 'WEIBULL') {
        return 'Weibull';
      }
      return 'MIS/PLW';
    },
    ...mapGetters(['userCompany', 'companySubscriptions', 'loggedInUser']),
    ...mapGetters('project', ['badStations'])
  },
  methods: {
    hasModuleByName(moduleName) {
      let companyHasModule = !!this.userCompany.modules.find(x => x.name.toLowerCase() === moduleName.toLowerCase());
      
      let userSubscriptionHasModule = false;
      if (this.loggedInUser.subscription != null) {
        let user_subscription = this.companySubscriptions.find(x => x.id == this.loggedInUser.subscription);
        if (user_subscription) {
          userSubscriptionHasModule = !!user_subscription.subscription_type.modules.find(x => x.name.toLowerCase() === moduleName.toLowerCase());
        }
      }
      
      return companyHasModule || userSubscriptionHasModule;
    },
    selectMetFileFormat(value) {
      this.metFileFormatRadioValue = value;
      this.$emit('setMetFileFormatRadioValue', value);

      if (value == 'A3N') {
        this.$emit('setCustomWeatherDataHeight', null);
      } else {
        this.$emit('setCustomWeatherDataHeight', this.customWeatherDataHeight);
      }
    },
    explanationText() {
      let projectText = '<b>Modelled data:</b> Choose this option if there no quality weather stations within 100km from your site or the nearby stations are known to have inferior quality.';
      let stationText = '<b>Measured data:</b> Ranked in order of Airports first and then ranked by distance from project.  <b>Airports provide the highest quality data.</b>';
      let futureMetText = '<b>Modelled data:</b> Study the impact of climate change by selecting a timeframe and climate scenario. '; 
      if (this.customMetUploadRadioValue==1) {
        return projectText;
      }

      if (this.customMetUploadRadioValue==0) {
        return stationText;
      }

      if (this.customMetUploadRadioValue==3) {
        return futureMetText;
      }
         
      return '';
    },
    fileExtensionDescription() {
      let text_mis = 'For uploading an MIS file, please export it using "PLW Export" option in MIS with an "Annual" time series selected. ';
      let text_tc360 = 'The file extension can be either ".csv" or ".tc360". The file cannot contain a header row.' + 
      '\nThe following columns are required:(name, format/unit)\n' + 'Date, yyyy-mm-dd_HH:MM (local time to project) | ' + ' WindSpeed, km/h | ' + ' RelativeHumidity, % | ' + ' DiffuseRadiation, W  | ' + 
      ' DirectRadiation, W | ' + ' Temperature, C | ' + ' SurfacePressure, kPa | ' + ' WindDirection, degrees';
      let text_a3n = 'For uploading an a3n file used in cladding up-crossing calculations';
      let text_weibull = 'For MET analysis using a weibull distribution';

      if (this.metFileFormatRadioValue=='MIS') {
        return text_mis;
      } else if (this.metFileFormatRadioValue=='A3N') {
        return text_a3n;
      } else if (this.metFileFormatRadioValue=='WEIBULL') {
        return text_weibull;
      } else {
        return text_tc360;
      }
    },
    loadMoreStations() {
      this.showCondensedStationList = false;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    setProjectIcon() {
      if (this.customMetUploadRadioValue == 1) {
        return this.greenIcon;
      } else {
        return this.redIcon;
      }
    },
    //custom validation for file type as the default Mime/type validation on Vue Formulate does not work in Firefox
    validateFileType(context) {
      if (context?.value?.files) {
        for (const file of context.value.files) {
          let file_extension = file.name.match(new RegExp('[^.]+$'))[0];
          if ( file_extension.toLowerCase() !== 'csv' && file_extension.toLowerCase() !== 'tc360' && file_extension.toLowerCase() !== 'a3n') {
            file.file['hasError'] = true;
            this.$forceUpdate();
            return false;
          }        
        }
      }
      return true;
    },
    fileUploaded() {
      this.$emit('setCustomMetUploadComplete', true);
      this.onCustomWeatherDataFileChange();
    },
    setCustomMetUploadRadioValue(value) {
      this.customMetUploadRadioValue = value;
      this.displayStations();
    },
    async displayStations() {
      if (this.customMetUploadRadioValue==0){
        this.stations = null;
        this.indexArray = [];
        await this.formatStations();        
        let val;
        this.stations = [];
        this.markeritems = [];
        let startDate, endDate, numYears;
        let indexN = 0;
        for (let index=0; index < this.stationData.length; index++) {
          val = this.stationData[index]['Horizontal Distance'].toFixed(2).toString();
          startDate = new Date(this.stationData[index]['Start Date']);
          endDate = new Date(this.stationData[index]['End Date']);
          numYears = (endDate.getTime() - startDate.getTime()) / (1000*3600*24*365);
          this.numOfYears = numYears;
          let lat_lng = this.stationData[index]['Location Lat,Lon'].split(',');
          if ((numYears >= this.minNumOfYears) && (indexN <= this.minNumOfYears)) {
            this.stations.push(`Rank ${indexN + 1} - ${this.stationData[index]['Name']}: ${this.stationData[index]['Location Lat,Lon']} (${val} km)`);
            this.markeritems.push({ id: index, name: this.stationData[index]['Name'], latlng: [+(lat_lng[0]), +(lat_lng[1])]});
          }
          this.allStations.push(`Rank ${indexN + 1} - ${this.stationData[index]['Name']}: ${this.stationData[index]['Location Lat,Lon']} (${val} km)`);
          this.allMarkers.push({ id: index, name: this.stationData[index]['Name'], latlng: [+(lat_lng[0]), +(lat_lng[1])]});
          this.indexArray.push(index);
          indexN += 1;
        }     
      }
    },
    async formatStations() {
      this.getBadStations();
      const res = await this.getStationData();
      const dataArray = res.split('\n').map(item => item.split(';')).filter(this.filterEmptyVals);
      const badWMOIDs = this.badStations.map(badStation => badStation.wmo_id);

      //if there are existing stations for this project, append their wmo ids to the badWMOID list so that they are filtered out.
      //there's no point in letting the user get the same met data twice
      for (let existing_station of this.existingStationsForProject) {
        badWMOIDs.push(existing_station.wmo_id);
      }
      

      this.stationData = [];
      for (let i=1; i < dataArray.length; i++) {
        if (dataArray[i][3] != '' && !badWMOIDs.includes(dataArray[i][3])) { // checks if WMO ID is not empty and does not belong to a bad station
          let newArr = {};
          for (let j=0; j < dataArray[0].length; j++) {
            let val = dataArray[i][j];
            if (j == 7 || j > 9) {
              val = parseFloat(val) / 1000;
              if (!this.airKeys.some(element => dataArray[i][5].toLowerCase().includes(element)) && j == 12) {
                val += 100;  //add 100 to Effective Distance.  this means that non-airports will have an effective distance > 100
              }             
            }
            newArr[dataArray[0][j]] = val;
          }
          this.stationData.push(newArr);
        }
      }
      let uniqueRecords = _.uniqBy(this.stationData, 'WMO ID');
      const sortedData = uniqueRecords.sort((a, b) => { return a['Effective Distance'] - b['Effective Distance'];});
      this.stationData = sortedData;        
    },
    async getStationData() {
      //note:  we add the wind related params we're looking for to make sure the station supports it but that doesn't mean a selected
      //station that contains these parameters isn't full of bad data.  Tokyo/Kashiwa in Japan is an example of a station that supports 
      //these parameters but we add to the bad stations list anyway because the data out of it is garbage.
      let retries = 0;
      const maxRetries = 3;
      while (retries <= maxRetries) {
        try {
          const res = await axios({
            url: `${this.apiBaseUrl}${this.latitude},${this.longitude}&parameters=wind_speed_10m:kmh,wind_dir_10m:d`,          
            method: 'get',
            timeout: 5000,
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true,
            auth: {
              username: this.userName,
              password: this.passWord
            }
          });
          return res.data;
        } catch (err) {
          console.error(err);
        }
        console.log('Error requesting station data, retrying...');
        retries++;
        await new Promise(r => setTimeout(r, 2000)); // Await for 2 sec to retry
      }
      console.log('Error requesting station data, please try again later') ; 
    },
    filterEmptyVals(elm) {
      return (elm.length > 1);
    },
    setStationIcon(latLon) {
      if (this.customMetUploadRadioValue == 1) {
        return this.blueIcon;
      } else {
        if (this.stationData && this.selectedStationData['latitude'] != undefined) {
          if ((Math.abs(this.selectedStationData['latitude'] - latLon[0]) < 0.001) && (Math.abs(this.selectedStationData['longitude'] - latLon[1]) < 0.001)) {
            return this.greenIcon;
          } else {
            return this.blueIcon;
          }
        } else {
          return this.blueIcon;
        }
      }
    },
    getSelectedStation(station) {
      this.selectedStation = station;
      let index = this.indexArray[parseInt(this.selectedStation.split(' ')[1], 10) - 1];
      let latLng = this.stationData[index]['Location Lat,Lon'].split(',');
      this.selectedStationData = {
        'name': this.stationData[index]['Name'],
        'wmo_id': this.stationData[index]['WMO ID'],
        'latitude': +(latLng[0]),
        'longitude': +(latLng[1]),
        'start_date': new Date(this.stationData[index]['Start Date']),
        'end_date': new Date(this.stationData[index]['End Date']),
      };      
    },
    onCustomWeatherDataFileChange() {
      let uploadedFilePath = this.customWeatherDataFile?.files?.at(0)?.path;
      if (!uploadedFilePath && this.customMetUploadRadioValue == 2) {
        this.$emit('setDisabled', true);
      } else if (uploadedFilePath && this.customMetUploadRadioValue == 2) {
        this.$emit('setDisabled', false);
      }
    },
    ...mapActions({
      getBadStations: 'project/getBadStations'
    })
  },
  watch: {
    customWeatherDataLabel(newValue) {
      this.$emit('setCustomWeatherDataLabel', newValue);
    },
    customWeatherDataHeight(newValue) {
      this.$emit('setCustomWeatherDataHeight', newValue);
    },
    displayStationsFlag() {
      this.displayStations();
    },
    selectedStationData(newValue) {
      this.$emit('setSelectedStationData', newValue);

      let selectedStationDataLength = Object.keys(newValue).length;
      if(selectedStationDataLength == 0 && this.customMetUploadRadioValue == 0) {
        this.$emit('setDisabled', true);
      } else if (selectedStationDataLength > 0 && this.customMetUploadRadioValue == 0) {
        this.$emit('setDisabled', false);
      }
    }, 
    customWeatherDataFile(newValue) {
      this.$emit('setCustomWeatherDataFile', newValue);
      this.onCustomWeatherDataFileChange();
    },
    customMetUploadRadioValue(newValue) {
      this.$emit('setCustomMetUploadRadioValue', newValue);

      if(newValue == 0) {
        this.$emit('setDisabled', Object.keys(this.selectedStationData).length == 0);
      } else if(newValue == 1) {
        this.$emit('setDisabled', false);
      } else if(newValue == 2) {
        this.$emit('setDisabled', !this.customWeatherDataFile?.files?.at(0)?.path);
      } else if (newValue == 3) {
        this.$emit('setDisabled', false);  //there are default values for 'timeframe' and 'climate scenario' so this option can always have the 'ok' button enabled
      }
    },
    timeframe(newValue) {
      this.$emit('setTimeframe', newValue);
    },
    climateScenario(newValue) {
      this.$emit('setClimateScenario', newValue);
    }
  }
};
</script>

<style scoped>
.map-legend-control {
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #aaa;
  background: rgba(0, 0, 0, 0.5);
}
.legend-marker{
  height: 23px;
}
</style>

<style>
.select-weather-dropdown button:first-child {
  text-align: left;
  font-size: 0.9em;
  color: var(--grey-900) !important;
  background: #FFF !important;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;
}

.select-weather-dropdown ul {
  z-index: 1001;
  padding: .75em 2em .75em .75em;
}

.select-weather-dropdown a {
  text-decoration: none;
  font-size: 0.9em;
  line-height: 1.2em;
  color: #000;
  padding: 0px 2px 1px;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;
}

.map-small {
  height: 17.188rem;
  position: relative;
}

.map-large {
  height: 20.313rem;
  position: relative;
}
</style>